<template>
    <div>
        <v-btn
            :background-color="$store.getters.getColorPalette().background1ColorCode"
            :color="$store.getters.getColorPalette().accent"
            small 
            text
            :dark="$store.getters.getColorPalette().isDark"
            outlined
            @click="dialog = !dialog"
        >
            {{ $store.getters.getTextMap().create_machine_group }} <v-icon right :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode">mdi-engine-outline</v-icon>
        </v-btn>
        <v-dialog v-model="dialog" max-width="290">  
            <CreateMachineGroup v-on:close="onClose" />
        </v-dialog>
    </div>
</template> 
<script>
import CreateMachineGroup from '@/components/crud_components/CreateMachineGroup'
export default {
    name:'CreateMachineGroupModal',
    components:{
        CreateMachineGroup
    },
    data () {
    return {
        dialog: false,
        valid:false,
    }
    },
    methods:{
        onClose(){
            this.dialog=false
            this.$emit('close')
        }
    }
}
</script>
<style scoped>
.emailgrp{
    border:3px solid;
    border-radius:20px;
}
.theme--light.v-card{
    background-color: #263238
}
</style>