<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" :info="info" v-on:close="showDismissibleAlert=false" />
        <v-row>
            <v-col>
                <v-form ref="orgForm" v-model="valid">
                    <v-card :color="$store.getters.getColorPalette(). backgroundColorCode" :dark="$store.getters.getColorPalette().isDark">
                        <v-card-title  class="headline">{{ title }}</v-card-title>
                        <v-card-text>
                            <v-text-field 
                                :dark="$store.getters.getColorPalette().isDark" 
                                :loading="loading"
                                v-model="form.name"
                                :counter="15"
                                :rules="[rules.required]"
                                :label="$store.getters.getTextMap().name"
                                required
                            ></v-text-field>
                            <v-text-field
                                :dark="$store.getters.getColorPalette().isDark" 
                                v-model="form.msg"
                                :counter="35"
                                :rules="[rules.required]"
                                :label="$store.getters.getTextMap().description"
                                required
                            ></v-text-field>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn :color="$store.getters.getColorPalette().submitbtnColor" @click="onSubmit">{{ $store.getters.getTextMap().submit }}</v-btn>
                            <v-btn :color="$store.getters.getColorPalette().cancelbtnColor" :dark="$store.getters.getColorPalette().isDark"  @click="onReset">{{  $store.getters.getTextMap().cancel  }}</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
    </v-container>           
</template>
<script>
import axios from 'axios';
import InfoAlert from '@/components/InfoAlert'
import FormRules from '@/utillities/FormRules'
export default {
    name:'CreateUserGroup',
    props:['userGroup'],
    components:{
        InfoAlert
    },
    data(){
        return {
            form:{
                name:null
            },
            api:'createUserGroup',
            title:'Create User Group',
            loading:false,
            info:null,
            infoMessage:"User Group Created",
            showDismissibleAlert:false,
            valid:false,
            rules:FormRules.rules
        }
    },
    mounted(){
      if(this.userGroup && this.userGroup.group_id){
        this.api='updateUserGroup'
        this.title='Update User Group'
        this.infoMessage="User Group Updated"
        this.form = Object.assign({},this.userGroup)
        // this.form.description = this.userGroup.msg
      }
    },
    methods:{
        onSubmit(){
            this.$refs.orgForm.validate()
            if(this.valid){
                this.loading = true;
                axios.post(this.$store.state.api + this.api, this.form,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response => {
                    if (response.data.status == "success" && response.data.group) {
                        this.$store.commit("addUserGroup",response.data.group);
                        this.$store.dispatch("refreshUserGroups")
                        this.info = this.infoMessage;
                        this.showDismissibleAlert = true;
                        this.loading = false;
                        this.onReset()
                        this.$emit('close')
                    } else {
                        this.info = response.data.msg;
                        this.showDismissibleAlert = true;
                    }
                    this.loading = false;
                })
                .catch(error => {
                this.onReset()
                this.info = error;
                this.showDismissibleAlert = true;
                this.loading = false;
                });
            }
        },
        onReset(){
            this.$refs.orgForm.reset()
            this.$emit('close')
        }
    }
}
</script>
<style scoped>
</style>