<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" :info="info" v-on:close="showDismissibleAlert=false" />
        <v-row>
            <v-col>
                <v-form ref="orgForm" v-model="valid">
                    <v-card :color="$store.getters.getColorPalette(). backgroundColorCode" :dark="$store.getters.getColorPalette().isDark">
                        <v-card-title  class="headline">{{ title }}</v-card-title>
                        <v-card-text>
                            <v-text-field 
                                :dark="$store.getters.getColorPalette().isDark" 
                                :loading="loading"
                                v-model="form.name"
                                :counter="30"
                                :rules="nameRules"
                                :label="$store.getters.getTextMap().name"
                                required
                            ></v-text-field>
                            <v-text-field
                                :dark="$store.getters.getColorPalette().isDark" 
                                v-model="form.description"
                                :counter="35"
                                :rules="msgRules"
                                :label="$store.getters.getTextMap().description"
                                required
                            ></v-text-field>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn :color="$store.getters.getColorPalette().submitbtnColor" @click="onSubmit">Submit</v-btn>
                            <v-btn :color="$store.getters.getColorPalette().cancelbtnColor" :dark="$store.getters.getColorPalette().isDark"  @click="onReset">Cancel</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
    </v-container>           
</template>
<script>
import axios from 'axios';
import InfoAlert from '@/components/InfoAlert'
export default {
    name:'CreateMachineGroup',
    props:['machineGroup'],
    components:{
        InfoAlert
    },
    data(){
        return {
            form:{
                name:null
            },
            api:'createMachineGroup',
            title:'Create Machine Group',
            loading:false,
            info:null,
            infoMessage:"Machine Group Created",
            showDismissibleAlert:false,
            valid:false,
            nameRules: [
                v => !!v || 'Name is required',
                v => /\S+/.test(v) || 'Name is required',
                v => (v && v.length <= 30 && v.length > 0) || 'Name must be less than 11 characters',
            ],
            msgRules: [
                v => !!v || 'Description is required',
                v => (v && v.length <= 35 && v.length > 0) || 'Description must be less than 35 characters',
            ],
        }
    },
    mounted(){
      if(this.machineGroup && this.machineGroup.machine_group_id){
        this.api='updateMachineGroup'
        this.title='Update Machine Group'
        this.infoMessage="Machine Group Updated"
        this.form = Object.assign({},this.machineGroup)
        this.form.description = this.machineGroup.msg
      }
    },
    methods:{
        onSubmit(){
            this.$refs.orgForm.validate()
            if(this.valid){
                this.loading = true;
                axios.post(this.$store.state.api + this.api, this.form,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response => {
                    if (response.data.status == "success" && response.data.group) {
                        this.$store.commit("addMachineGroup",response.data.group);
                        this.$store.dispatch("refreshMachineGroups")
                        this.info = this.infoMessage;
                        this.showDismissibleAlert = true;
                        this.loading = false;
                        this.onReset()
                        this.$emit('close')
                    } else {
                        this.info = response.data.msg;
                        this.showDismissibleAlert = true;
                    }
                    this.loading = false;
                })
                .catch(error => {
                this.onReset()
                this.info = error;
                this.showDismissibleAlert = true;
                this.loading = false;
                });
            }
        },
        onReset(){
            this.$refs.orgForm.reset()
            this.$emit('close')
        }
    }
}
</script>
<style scoped>
</style>