<template>

    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-card flat :color="$store.getters.getColorPalette().backgroundColorCode" :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}">
        <v-card-title>{{department.name}} <v-progress-circular
  indeterminate
  v-if="loading"
  color="primary"
></v-progress-circular>
<v-spacer></v-spacer>
<v-dialog
  v-model="dialog"
  max-width="290"
  >
  <CreateDepartment :department="department" v-on:close="onClose" />
</v-dialog>
<span v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])">
  <v-icon
    small
    class="mr-2"
    v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['betaaccess'])"
    @click="dialog=!dialog"
  >
    mdi-pencil
  </v-icon>
    <DeleteConfirmation  v-on:confirm="deleteDepartment()"  title="Delete Confirmation"  description="Are you sure you want to delete this?">  
  <v-icon
    small
  >
    mdi-delete
  </v-icon>
</DeleteConfirmation>
</span>
</v-card-title>
    <v-divider></v-divider>
    <v-card-subtitle >
        <v-row v-if="!loading">
            <v-col></v-col>
        </v-row>
    
    <v-row v-if="loading">
        <v-col>
            <v-skeleton-loader
                        v-if="loading"
                        class="mx-auto"
                        type="text"
                    ></v-skeleton-loader>
        </v-col>
        <v-col>
            <v-skeleton-loader
                        v-if="loading"
                        class="mx-auto"
                        type="text"
                    ></v-skeleton-loader>
        </v-col>
    </v-row>
    
    
    <v-row>
        <v-col>
            <v-expansion-panels multiple hover tile>
          <v-expansion-panel
            :style="{
              border:
                '1px solid ' + $store.getters.getColorPalette().cardBorderColor,
              'background-color':
                $store.getters.getColorPalette().background2ColorCode,
            }"
            :dark="$store.getters.getColorPalette().isDark"
          >
            <v-expansion-panel-header
              ><h3 :color="$store.getters.getColorPalette().accentCode">
                {{ $store.getters.getTextMap().users }}
              </h3></v-expansion-panel-header
            >
            <v-expansion-panel-content>
                <DepartmentUsers :department="department" />
            </v-expansion-panel-content>
            <v-expansion-panel
            :style="{
              border:
                '1px solid ' + $store.getters.getColorPalette().cardBorderColor,
              'background-color':
                $store.getters.getColorPalette().background2ColorCode,
            }"
            :dark="$store.getters.getColorPalette().isDark"
          >
            <v-expansion-panel-header
              ><h3 :color="$store.getters.getColorPalette().accentCode">
                {{ $store.getters.getTextMap().items }}
              </h3></v-expansion-panel-header
            >
            <v-expansion-panel-content>
                <DepartmentItems :department="department" />
                </v-expansion-panel-content>
            </v-expansion-panel>

          </v-expansion-panel>
        </v-expansion-panels>
            
        </v-col>
    </v-row>
    </v-card-subtitle>
        </v-card>
    </v-container>

</template>

<script>
import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'
import DeleteConfirmation from '@/components/DeleteConfirmation'
// import FormRules from '@/utillities/FormRules'
import CreateDepartment from '@/components/crud_components/CreateDepartment'
import DepartmentUsers from '@/components/lists/departments/DepartmentUsers.vue'
import DepartmentItems from '@/components/lists/departments/DepartmentItems.vue'
export default {
name:'Department',
props:['department'],
components:{
    InfoAlert,
    CreateDepartment,
    DepartmentUsers,
    DepartmentItems,
    DeleteConfirmation
},
mounted(){
    // this.getUsers()
},
data(){
    return {
        showDialog:false,
        loading:false,
        info:'',
        showDismissibleAlert:false,
        addDialog:false,
        valid:false,
        dialog: false,

    }
},

computed:{    
    
},

methods:{
  onClose(){
        this.dialog=false,
        this.$emit('close')
        },
   
    deleteDepartment(){

        axios.post(this.$store.state.api+'deleteDepartment',this.department,{headers: {
                Authorization: 'Bearer '+ this.$store.state.jwt
                }})
                .then(response=>{
                    if(response.data.status=='success'){
                        this.$store.dispatch('refreshDepartments')
                        this.loading=false
                        this.info='The Group has been deleted'
                        this.showDismissibleAlert=true
                    }else{
                        this.loading=false
                        this.info=response.data.msg
                        this.showDismissibleAlert=true
                    }
                }).catch(err=>{
                    this.loading=false
                    this.info=err
                    this.showDismissibleAlert=true

                })

    },
    
  cancel(){
      this.showDialog=false
    },
    confirm(){
      this.showDialog=false
    }    
},
watch:{
    // department: function (){
    //     this.getUsers()
    // }
}

}
</script>