<template>
    <v-container flex>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-row v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess']) ">
        <v-col cols="1">
            <span><v-btn   fab :dark="$store.getters.getColorPalette().isDark" @click="addDialog=!addDialog" color="blue" >
    <v-icon>mdi-plus</v-icon>
  </v-btn></span>
        </v-col>
        <v-col v-if="addDialog">
            <v-form
ref="addForm"
v-model="valid">
            <v-row>
                
                <v-col >
                    <v-autocomplete
                    :items="itemCodeOptions"
                    :dark="$store.getters.getColorPalette().isDark" 
                    :color="$store.getters.getColorPalette().inputtextColor"
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                    outlined
                    dense
                    small-chips
                    :label="$store.getters.getTextMap().item_code"
                    item-text="label"
                    item-value="value"
                    v-model="form.item_code"
                ></v-autocomplete>
                </v-col>
                
                
                <v-col>
                    <v-btn
                    v-if="!loading"
                    :color="$store.getters.getColorPalette().submitbtnColor"
                    text
                    @click="addItemToDepartment"
                    >
                    {{$store.getters.getTextMap().add}}
                </v-btn>
            </v-col>
            <v-col align="right">
                <v-icon @click="getItemCodes">mdi-refresh</v-icon>
            </v-col>
        </v-row>
    </v-form>
        </v-col>
        
    </v-row>
    <v-row>
        <v-col>

            <v-simple-table :dark="$store.getters.getColorPalette().isDark"  height="150px">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">
                                <strong>{{$store.getters.getTextMap().name}}</strong>
                            </th>
                            <th class="text-left">
                                <strong>{{$store.getters.getTextMap().item_code}}</strong>
                            </th>
                            <th class="text-left">
                                <strong>{{$store.getters.getTextMap().material}}</strong>
                            </th>
                            <th class="text-left">
                                <strong>{{$store.getters.getTextMap().material_group}}</strong>
                            </th>
                            <th class="text-left">
                                <strong>{{$store.getters.getTextMap().material_desc}}</strong>
                            </th>
                            <th class="text-left">
                                <strong>{{$store.getters.getTextMap().uom}}</strong>
                            </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                            v-for="item in items"
                            :key="item.item_code"
                            >
                            <td>{{ item.item_name }}</td>
                            <td>{{ item.item_code }}</td>
                            <td>{{ item.material }}</td>
                            <td>{{ item.material_group }}</td>
                            <td>{{ item.material_desc }}</td>
                            <td>{{ item.uom }}</td>
                            <td v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['uam']) ">
                                <DeleteConfirmation  v-on:confirm="removeItemFromDepartment(item)"  title="Delete Confirmation"  description="Are you sure you want to Remove User?"> 
                                    <v-icon small color="red" >mdi-delete</v-icon>
                                </DeleteConfirmation>
                            </td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
                </v-row>
    </v-container>
</template>
<script>
import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'
import DeleteConfirmation from '@/components/DeleteConfirmation'
import FormRules from '@/utillities/FormRules'
export default{
    name:'DepartmentUsers',
    props:['department'],
    components:{
    InfoAlert,

    DeleteConfirmation
},
mounted(){
    this.getItems()
    this.getItemCodes()
},
    data(){
        return {
            showDialog:false,
        loading:false,
        info:'',
        showDismissibleAlert:false,
        addDialog:false,
        valid:false,
        dialog: false,
        items:[],
        itemCodes:[],
        form:{
        },
        stringRules: [
    v => !!v || 'Required',
    v => /\S+/.test(v) || 'Required',
    v => (v && v.length <= 30 && v.length > 0) || 'Cannot be more than 30 characters',
  ],
  rules:FormRules.rules,

        }
    },
    computed:{
        itemCodeOptions(){
        let op=[]
        if(this.itemCodes){
            for(let u of this.itemCodes){
                if(!(this.items && this.items.find(i=>i.item_code==u.item_code))){
                    op.push({
                        label:u.item_code,
                        value:u.item_code
                    })
                }
            }
        }
            //console.log(this.$store.getters.getNodesByWorkspaceId(this.workspace.workspace_id))
            return op
        },
    },
    methods:{
        getItemCodes(){
            this.loading=true
            axios.get(this.$store.state.api+'itemCodes',{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
                if(response.data.status=='success'){
                    // this.itemCodeOptions=[]
                    this.itemCodes=response.data.data
                    // if(items && items.length>0){
                    //     for(let item of items){
                    //         this.itemCodeOptions.push({
                    //             label:item.item_code,
                    //             value:item.item_code
                    //         })
                    //     }
                    // }
                    
                }else{
                    this.info=response.data.msg
                    this.showDismissibleAlert=true
                }
                this.loading=false
            }).catch(err=>{
                this.loading=false
                this.info=err
                this.showDismissibleAlert=true 
            })
            
        },
        addItemToDepartment(){
        this.$refs.addForm.validate()
        if(this.valid){
            this.loading=true
      this.form['department_id']=this.department.department_id

      axios.post(this.$store.state.api+'addItemToDepartment',this.form,{headers: {
                Authorization: 'Bearer '+ this.$store.state.jwt
                }})
                .then(response=>{
                    if(response.data.status=='success'){
                        //this.$store.dispatch('refreshListData')
                        this.loading=false
                        this.info='The Item has been Added'
                        this.showDismissibleAlert=true
                        this.getItems()
                        // this.items.push(response.data.data)
                    }else{
                        this.loading=false
                        this.info=response.data.msg
                        this.showDismissibleAlert=true
                    }
                }).catch(err=>{
                    this.loading=false
                    this.info=err
                    this.showDismissibleAlert=true

                })
    }
    },
    removeItemFromDepartment(item){

        axios.post(this.$store.state.api+'removeItemFromDepartment',item,{headers: {
                Authorization: 'Bearer '+ this.$store.state.jwt
                }})
                .then(response=>{
                    if(response.data.status=='success'){
                        //this.$store.dispatch('refreshListData')
                        this.loading=false
                        this.info='The Item has been removed'
                        this.showDismissibleAlert=true
                        this.items=this.items.filter((t)=>{return t.item_code!=item.item_code})
                    }else{
                        this.loading=false
                        this.info=response.data.msg
                        this.showDismissibleAlert=true
                    }
                }).catch(err=>{
                    this.loading=false
                    this.info=err
                    this.showDismissibleAlert=true

                })

        },
        getItems(){
        this.loading=true
        axios.post(this.$store.state.api+'getItemsByDepartment',this.department,{headers: {
                Authorization: 'Bearer '+ this.$store.state.jwt
                }})
                .then(response=>{
                    if(response.data.status=='success'){
                        this.items=[]
                        if(response.data.data){
                            this.items=response.data.data
                        }
                        this.loading=false
                    }else{
                        this.loading=false
                        this.info=response.data.msg
                        this.showDismissibleAlert=true
                    }
                }).catch(err=>{
                    this.loading=false
                    this.info=err
                    this.showDismissibleAlert=true

                })
    },
    },
    watch:{
        department: function (){
            this.getItems()
        }
    }
}
</script>