<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-card flat :color="$store.getters.getColorPalette().backgroundColorCode" :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}">
            <v-card-title>{{machineGroup.name}} 
                <v-progress-circular indeterminate v-if="loading" color="primary"></v-progress-circular>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="290"><CreateMachineGroup :machineGroup="machineGroup" v-on:close="onClose" /></v-dialog>
                <span v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])">
                    <v-icon small class="mr-2" v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])" @click="dialog=!dialog">
                        mdi-pencil
                    </v-icon>
                    <DeleteConfirmation  v-on:confirm="deleteGroup()"  title="Delete Confirmation"  description="Are you sure you want to delete this?">  
                        <v-icon small>mdi-delete</v-icon>
                    </DeleteConfirmation>
                </span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-subtitle >
                <v-row v-if="loading">
                    <v-col>
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    </v-col>
                    <v-col>
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    </v-col>
                </v-row>
                <v-row v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess']) ">
                    <v-col cols="1">
                        <span>
                            <v-btn small fab :dark="$store.getters.getColorPalette().isDark" @click="addDialog=!addDialog" color="blue" >
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </span>
                    </v-col>
                </v-row>
                <v-row v-if="addDialog">
                    <v-col>
                        <v-form ref="addForm" v-model="valid">
                            <v-row>
                                <v-col>
                                <v-text-field
                                    v-if="!loading"
                                    v-model="form.machine_label"
                                    :label="$store.getters.getTextMap().label"
                                    :rules="nameRules"
                                    required
                                    :dark="$store.getters.getColorPalette().isDark" 
                                ></v-text-field>
                                </v-col>
                                <v-col>
                                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                <v-select  
                                    :dark="$store.getters.getColorPalette().isDark" 
                                :color="$store.getters.getColorPalette().inputtextColor"
                                    v-if="!loading"
                                    v-model="workspace"
                                    :items="workspaceOptions"
                                    :label="$store.getters.getTextMap().workspace"
                                    outlined
                                    item-text="label"
                                    item-value="value"
                                :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                    required
                                    :rules="objectRules"
                                ></v-select>
                            </v-col>
                            <v-col>
                                <v-select
                                    :dark="$store.getters.getColorPalette().isDark" 
                                :color="$store.getters.getColorPalette().inputtextColor"
                                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                    v-if="!loading"
                                    v-model="machine"
                                    :items="machineOptions"
                                    :label="$store.getters.getTextMap().machine"
                                    outlined
                                    item-text="label"
                                    item-value="value"
                                ></v-select>
                            </v-col>
                            <v-col>
                                <v-btn v-if="!loading" :color="$store.getters.getColorPalette().submitbtnColor" text @click="addTupleToGroup">
                                    Add
                                </v-btn>
                            </v-col>
                            </v-row>
                        </v-form>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-simple-table height="150px">
                            <template v-slot:default>
                            <thead>
                                <tr>
                                <th class="text-left">
                                    <strong>Name</strong>
                                </th>
                                <th class="text-left">
                                    <strong>Machine ID</strong>
                                </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                v-for="item in tuples"
                                :key="item.machine_id"
                                >
                                <td>{{ item.label }}</td>
                                <td>{{ item.machine_id }}</td>
                                <td v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess']) "><v-icon small color="red" @click="deleteTupleFromGroup(item)">mdi-delete</v-icon></td>
                                </tr>
                            </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                </v-row>
            </v-card-subtitle>
        </v-card>
    </v-container>
</template>
<script>
import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'
import DeleteConfirmation from '@/components/DeleteConfirmation'
import FormRules from '@/utillities/FormRules'
import CreateMachineGroup from '@/components/crud_components/CreateMachineGroup'

export default {
name:'MachineGroup',
props:['machineGroup'],
components:{
    InfoAlert,
    CreateMachineGroup,
    DeleteConfirmation
},
mounted(){
    this.getTuples()
},
data(){
    return {
        showDialog:false,
        loading:false,
        info:'',
        showDismissibleAlert:false,
        addDialog:false,
        valid:false,
        dialog: false,
        workspace:null,
        machine:null,
        machineOptions:[],
        tuples:[],
        form:{
          address:null,
          name:null,
        },
        stringRules: [
            v => !!v || 'Required',
            v => /\S+/.test(v) || 'Required',
            v => (v && v.length <= 30 && v.length > 0) || 'Cannot be more than 30 characters',
        ],
        objectRules:[
            v => !!v || 'Required',
        ],
        bitRules:[
            v => !!v || 'Required',
            v => /[0-9]+$/.test(v) || 'It should be a number',
            v => (v && !isNaN(v))|| 'It should be a valid number',
            v => (v && v.length <= 2 && v.length > 0) || "It can't be more that 2 digits",
        ],
        emailRules:FormRules.emailRules,
        nameRules:FormRules.nameRules
    }
},
computed:{
    workspaceOptions(){
        let op=[]
        let g=this.$store.state.workspaces
            for(let i of g){
                op.push( {value:{workspace_id:i.workspace_id,workspace_name:i.name}, label: i.name })
            }
        return op
    },
},
methods:{
    getMachineList(){
        let op=[]
        if(this.workspace){
            let payload={
                workspace_id:this.workspace.workspace_id
            }
            this.loading = true;
            axios.post(this.$store.state.api + "getMachinesByWorkspace", payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response => {
            if (response.data.status == "success") {
                let machines=response.data.machines
                for(let i of machines){
                    if(i && i.name){
                        op.push({value:{machine_id:i.machine_id,machine_name:i.name,workspace_id:this.workspace.workspace_id}, label: i.name })
                    }else{
                        op.push({value:i.machine_id, label: i.machine_id })
                    }
                }
            } else {
                this.info = response.data.msg;
                this.showDismissibleAlert = true;
            }
            this.loading = false;
            })
            .catch(error => {
                this.info = error;
                this.showDismissibleAlert = true;
                this.loading = false;
            });
        }
            this.machineOptions=op
        },
    onClose(){
        this.dialog=false,
        this.$emit('close')
    },
    getTuples(){
        this.loading=true
        axios.post(this.$store.state.api+'getTuplesByMachineGroup',this.machineGroup,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
                if(response.data.status=='success'){
                    this.tuples=response.data.data
                    this.loading=false
                }else{
                    this.loading=false
                    this.info=response.data.msg
                    this.showDismissibleAlert=true
                }
            }).catch(err=>{
                this.loading=false
                this.info=err
                this.showDismissibleAlert=true
            })
    },
    deleteGroup(){
        axios.post(this.$store.state.api+'deleteMachineGroup',this.machineGroup,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
        .then(response=>{
            if(response.data.status=='success'){
                this.$store.dispatch('refreshMachineGroups')
                this.loading=false
                this.info='The Machine Group has been deleted'
                this.showDismissibleAlert=true
            }else{
                this.loading=false
                this.info=response.data.msg
                this.showDismissibleAlert=true
            }
        }).catch(err=>{
            this.loading=false
            this.info=err
            this.showDismissibleAlert=true
        })
    },
    deleteTupleFromGroup(tuple){
        axios.post(this.$store.state.api+'deleteMachineFromGroup',tuple,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
        .then(response=>{
            if(response.data.status=='success'){
                //this.$store.dispatch('refreshListData')
                this.loading=false
                this.info='The Machine has been deleted'
                this.showDismissibleAlert=true
                this.tuples=this.tuples.filter((t)=>{return t.machine_id!=tuple.machine_id})
            }else{
                this.loading=false
                this.info=response.data.msg
                this.showDismissibleAlert=true
            }
        }).catch(err=>{
            this.loading=false
            this.info=err
            this.showDismissibleAlert=true
        })
    },
    addTupleToGroup(){
        this.$refs.addForm.validate()
        if(this.valid){
            this.loading=true
            this.form['machine_group_id']=this.machineGroup.machine_group_id
            this.form.machine_id = this.machine.machine_id
            axios.post(this.$store.state.api+'addTupleToMachineGroup',this.form,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
                if(response.data.status=='success'){
                    this.loading=false
                    this.info='The Machine has been added'
                    this.showDismissibleAlert=true
                    this.tuples.push(response.data.tuple)
                }else{
                    this.loading=false
                    this.info=response.data.msg
                    this.showDismissibleAlert=true
                }
            }).catch(err=>{
                this.loading=false
                this.info=err
                this.showDismissibleAlert=true
            })
        }
    },
  cancel(){
      this.showDialog=false
    },
    confirm(){
      this.showDialog=false
    }    
},
watch:{
    machineGroup: function (){
        this.getTuples()
    },
    workspace(){
        this.getMachineList()
    },
}
}
</script>