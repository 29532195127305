<template>
  <div>
    <v-btn :background-color="$store.getters.getColorPalette().background1ColorCode" :color="$store.getters.getColorPalette().accentCode" :dark="$store.getters.getColorPalette().isDark" outlined small text @click="dialog = !dialog">
      {{ $store.getters.getTextMap().create_view }} {{ $store.getters.getTextMap().notification_groups }}<v-icon right :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode">mdi-email-outline</v-icon>
    </v-btn>
    <v-dialog v-model="dialog" max-width="290">
      <CreateEmailGroup v-on:close="onClose" />
    </v-dialog>
    </div>
</template>
<script>
import CreateEmailGroup from '@/components/crud_components/CreateEmailGroup'
  export default {
      name:'CreateEmailGroupModal',
      components:{
          CreateEmailGroup
      },
    data () {
      return {
        dialog: false,
        valid:false,
      }
    },
    methods:{
        onClose(){
            this.dialog=false
            this.$emit('close')
            }

    }
  }
</script>
<style scoped>
.emailgrp{
   border:3px solid;
  border-radius:20px;

}
.theme--light.v-card{
  background-color: #263238
}
</style>