<template>
    <v-container fluid>
      <v-card :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" flat :dark="$store.getters.getColorPalette().isDark" >
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$store.getters.getTextMap().search"
            single-line
            hide-details
            :dark="$store.getters.getColorPalette().isDark" 
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="$store.state.paramGroups"
          item-key="param_group_id"
          :search="search"
          :single-expand="true"
          show-expand
          :expanded.sync="expanded"
          :loading="loading"
        loading-text="Loading... Please wait"
       :dark="$store.getters.getColorPalette().isDark" 
        >
        <template v-slot:top>
          <v-toolbar
            flat
            :dark="$store.getters.getColorPalette().isDark" 
          >
            <v-toolbar-title>{{ $store.getters.getTextMap().param_groups }}</v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-spacer></v-spacer>
              </v-toolbar>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <ParamGroup :paramGroup="item" />
          </td>
        </template>
              
        </v-data-table>
      </v-card>
    </v-container>
    </template>
    
    <script>
    //import CreateNotificationGroupModal from '@/components/modals/CreateActionBottomSheet'
    import ParamGroup from '@/components/items/ParamGroup'
    // import ColorUtility from '@/utillities/ColorUtility'
    export default {
        name:'ParamGroupTable',
        components:{
            //CreateActionBottomSheet,
            ParamGroup
        },
        data(){
            return {
                search:'',
                loading:false,
                expanded: [],
                headers: [
              {
                text: 'ID',
                align: 'start',
                filterable: false,
                value: 'param_group_id',
                class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName
              },
              { text: 'Name', value: 'name', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'Description', value: 'msg', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              
              
            ],
            }
        },
        computed:{
            groups(){
                //console.log(this.$store.getters.getNodesByWorkspaceId(this.workspace.workspace_id))
                return this.$store.getters.getParamGroups()
            }
        },
    }
    </script>
    <style scoped>
    
    .v-card__title{
      background-color:#BDBDBD00;
    }
    .theme--light.v-toolbar.v-sheet{
      background-color: #B2DFDB00;
    }
    .theme--light.v-data-table{
       background-color: #B2DFDB00;
    }
    </style>