<template>
    <v-container flex>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-divider></v-divider>
        <h2 class="py-4" :style="{color:$store.getters.getColorPalette().accentCode}">{{ $store.getters.getTextMap().issuers_in_department }}</h2>
        <v-row v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess']) ">
        <v-col cols="1">
            <span><v-btn  small fab :dark="$store.getters.getColorPalette().isDark" @click="addIssueDialog=!addIssueDialog" color="blue" >
    <v-icon small >mdi-plus</v-icon>
  </v-btn></span>
        </v-col>
        <v-col v-if="addIssueDialog">
            <v-form
ref="addIssueForm"
v-model="issuerValid">
            <v-row>
                
                <v-col >
                    
                <v-autocomplete
                    :items="issueUserOptions"
                    :dark="$store.getters.getColorPalette().isDark" 
                    :color="$store.getters.getColorPalette().inputtextColor"
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                    outlined
                    dense
                    small-chips
                    :label="$store.getters.getTextMap().user_id"
                    item-text="label"
                    item-value="value"
                    v-model="issueForm.userid"
                ></v-autocomplete>
                </v-col>
                
                
                <v-col>
                    <v-btn
      v-if="!loading"
      :color="$store.getters.getColorPalette().submitbtnColor"
        text
        @click="addIssuerToDepartment"
      >
    {{$store.getters.getTextMap().add}}
      </v-btn>
                </v-col>
            </v-row>
            </v-form>
        </v-col>
        
    </v-row>
        <v-row>
            <v-col>
                <v-simple-table :dark="$store.getters.getColorPalette().isDark"  height="150px">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">
                                <strong>{{$store.getters.getTextMap().name}}</strong>
                            </th>
                            <th class="text-left">
                                <strong>{{$store.getters.getTextMap().employee_id}}</strong>
                            </th>
                            <th class="text-left">
                                <strong>{{$store.getters.getTextMap().user_id}}</strong>
                            </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                            v-for="item in issuers"
                            :key="item.userid"
                            >
                            <td>{{ item.name }}</td>
                            <td>{{ item.emp_id }}</td>
                            <td>{{ item.userid }}</td>
                            <td v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['uam']) ">
                                <DeleteConfirmation  v-on:confirm="removeIssuerFromDepartment(item)"  title="Delete Confirmation"  description="Are you sure you want to Remove User?"> 
                                    <v-icon small color="red" >mdi-delete</v-icon>
                                </DeleteConfirmation>
                            </td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <h2 class="py-4" :style="{color:$store.getters.getColorPalette().accentCode}">{{ $store.getters.getTextMap().authorisers_in_department }}</h2>
        <v-row v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess']) ">
        <v-col cols="1">
            <span><v-btn  small fab :dark="$store.getters.getColorPalette().isDark" @click="addAuthDialog=!addAuthDialog" color="blue" >
    <v-icon small >mdi-plus</v-icon>
  </v-btn></span>
        </v-col>
        <v-col v-if="addAuthDialog">
            <v-form
ref="addAuthForm"
v-model="authValid">
            <v-row>
                
                <v-col >
                    
                <v-autocomplete
                    :items="authUserOptions"
                    :dark="$store.getters.getColorPalette().isDark" 
                    :color="$store.getters.getColorPalette().inputtextColor"
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                    outlined
                    dense
                    small-chips
                    :label="$store.getters.getTextMap().user_id"
                    item-text="label"
                    item-value="value"
                    v-model="authForm.userid"
                ></v-autocomplete>
                </v-col>
                
                
                <v-col>
                    <v-btn
      v-if="!loading"
      :color="$store.getters.getColorPalette().submitbtnColor"
        text
        @click="addAuthoriserToDepartment"
      >
    {{$store.getters.getTextMap().add}}
      </v-btn>
                </v-col>
            </v-row>
            </v-form>
        </v-col>
        
    </v-row>
        <v-row>
            <v-col>
                <v-simple-table :dark="$store.getters.getColorPalette().isDark"  height="150px">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">
                                <strong>{{$store.getters.getTextMap().name}}</strong>
                            </th>
                            <th class="text-left">
                                <strong>{{$store.getters.getTextMap().employee_id}}</strong>
                            </th>
                            <th class="text-left">
                                <strong>{{$store.getters.getTextMap().user_id}}</strong>
                            </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                            v-for="item in authorisers"
                            :key="item.userid"
                            >
                            <td>{{ item.name }}</td>
                            <td>{{ item.emp_id }}</td>
                            <td>{{ item.userid }}</td>
                            <td v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['uam']) ">
                                <DeleteConfirmation  v-on:confirm="removeAuthoriserFromDepartment(item)"  title="Delete Confirmation"  description="Are you sure you want to Remove User?"> 
                                    <v-icon small color="red" >mdi-delete</v-icon>
                                </DeleteConfirmation>
                            </td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <h2 class="py-4" :style="{color:$store.getters.getColorPalette().accentCode}">{{ $store.getters.getTextMap().users_in_department }}</h2>

        <v-row v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess']) ">
        <v-col cols="1">
            <span><v-btn  small fab :dark="$store.getters.getColorPalette().isDark" @click="addDialog=!addDialog" color="blue" >
    <v-icon small >mdi-plus</v-icon>
  </v-btn></span>
        </v-col>
        <v-col v-if="addDialog">
            <v-form
ref="addForm"
v-model="valid">
            <v-row>
                
                <v-col >
                    
<v-autocomplete
                    :items="userOptions"
                    :dark="$store.getters.getColorPalette().isDark" 
                    :color="$store.getters.getColorPalette().inputtextColor"
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                    outlined
                    dense
                    small-chips
                    :label="$store.getters.getTextMap().user_id"
                    item-text="label"
                    item-value="value"
                    v-model="form.userid"
                ></v-autocomplete>
                </v-col>
                
                
                <v-col>
                    <v-btn
      v-if="!loading"
      :color="$store.getters.getColorPalette().submitbtnColor"
        text
        @click="addUserToDepartment"
      >
    {{$store.getters.getTextMap().add}}
      </v-btn>
                </v-col>
            </v-row>
            </v-form>
        </v-col>
        
    </v-row>
    <v-row>
        <v-col>

            <v-simple-table height="150px">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">
                                <strong>{{$store.getters.getTextMap().name}}</strong>
                            </th>
                            <th class="text-left">
                                <strong>{{$store.getters.getTextMap().employee_id}}</strong>
                            </th>
                            <th class="text-left">
                                <strong>{{$store.getters.getTextMap().user_id}}</strong>
                            </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                            v-for="item in users"
                            :key="item.userid"
                            >
                            <td>{{ item.name }}</td>
                            <td>{{ item.emp_id }}</td>
                            <td>{{ item.userid }}</td>
                            <td v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['uam']) ">
                                <DeleteConfirmation  v-on:confirm="removeUserFromDepartment(item)"  title="Delete Confirmation"  description="Are you sure you want to Remove User?"> 
                                    <v-icon small color="red" >mdi-delete</v-icon>
                                </DeleteConfirmation>
                            </td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
                </v-row>
    </v-container>
</template>
<script>
import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'
import DeleteConfirmation from '@/components/DeleteConfirmation';
import FormRules from '@/utillities/FormRules'
export default{
    name:'DepartmentUsers',
    props:['department'],
    components:{
    InfoAlert,
    DeleteConfirmation
},
mounted(){
    this.getUsers()
    this.getAuthorisers()
    this.getIssuers()
},
    data(){
        return {
            showDialog:false,
        loading:false,
        info:'',
        showDismissibleAlert:false,
        addDialog:false,
        addAuthDialog:false,
        valid:false,
        authValid:false,
        issuerValid:false,
        dialog: false,
        authDialog: false,
        addIssueDialog: false,
        users:[],
        authorisers:[],
        issuers:[],
        form:{
        },
        authForm:{
        },
        issueForm:{
        },
        stringRules: [
    v => !!v || 'Required',
    v => /\S+/.test(v) || 'Required',
    v => (v && v.length <= 30 && v.length > 0) || 'Cannot be more than 30 characters',
  ],
  rules:FormRules.rules,
  bitRules:[
      v => !!v || 'Required',
      v => /[0-9]+$/.test(v) || 'It should be a number',
      v => (v && !isNaN(v))|| 'It should be a valid number',
      v => (v && v.length <= 2 && v.length > 0) || "It can't be more that 2 digits",
  ],
        }
    },
    computed:{
        userOptions(){
        let op=[]
        if(this.$store.state.UAMUsers){
            for(let u of this.$store.state.UAMUsers){
                if(!(this.users && this.users.find(us=>us.userid==u.userid))){
                    op.push({
                        label:u.name,
                        value:u.userid
                    })
                }
            }
        }
            //console.log(this.$store.getters.getNodesByWorkspaceId(this.workspace.workspace_id))
            return op
        },
        authUserOptions(){
        let op=[]
        if(this.users){
            for(let u of this.users){
                if(!(this.authorisers && this.authorisers.find(us=>us.userid==u.userid))){
                    op.push({
                        label:u.name,
                        value:u.userid
                    })
                }
            }
        }
            //console.log(this.$store.getters.getNodesByWorkspaceId(this.workspace.workspace_id))
            return op
        },
        issueUserOptions(){
        let op=[]
        if(this.users){
            for(let u of this.users){
                if(!(this.issuers && this.issuers.find(us=>us.userid==u.userid))){
                    op.push({
                        label:u.name,
                        value:u.userid
                    })
                }
            }
        }
            //console.log(this.$store.getters.getNodesByWorkspaceId(this.workspace.workspace_id))
            return op
        },
    },
    methods:{
        addUserToDepartment(){
        this.$refs.addForm.validate()
        if(this.valid){
            this.loading=true
      this.form['department_id']=this.department.department_id

      axios.post(this.$store.state.api+'addUserToDepartment',this.form,{headers: {
                Authorization: 'Bearer '+ this.$store.state.jwt
                }})
                .then(response=>{
                    if(response.data.status=='success'){
                        //this.$store.dispatch('refreshListData')
                        this.loading=false
                        this.info='The User has been Added'
                        this.showDismissibleAlert=true
                        this.getUsers()
                        // this.users.push(response.data.data)
                    }else{
                        this.loading=false
                        this.info=response.data.msg
                        this.showDismissibleAlert=true
                    }
                }).catch(err=>{
                    this.loading=false
                    this.info=err
                    this.showDismissibleAlert=true

                })
    }
    },
    removeUserFromDepartment(user){

        axios.post(this.$store.state.api+'removeUserFromDepartment',user,{headers: {
                Authorization: 'Bearer '+ this.$store.state.jwt
                }})
                .then(response=>{
                    if(response.data.status=='success'){
                        //this.$store.dispatch('refreshListData')
                        this.loading=false
                        this.info='The User has been deleted'
                        this.showDismissibleAlert=true
                        // this.users=this.users.filter((t)=>{return t.userid!=user.userid})
                        this.getUsers()
                    }else{
                        this.loading=false
                        this.info=response.data.msg
                        this.showDismissibleAlert=true
                    }
                }).catch(err=>{
                    this.loading=false
                    this.info=err
                    this.showDismissibleAlert=true

                })

        },
        addIssuerToDepartment(){
        this.$refs.addIssueForm.validate()
        if(this.issuerValid){
            this.loading=true
      this.issueForm['department_id']=this.department.department_id

      axios.post(this.$store.state.api+'addItemIssuer',this.issueForm,{headers: {
                Authorization: 'Bearer '+ this.$store.state.jwt
                }})
                .then(response=>{
                    if(response.data.status=='success'){
                        //this.$store.dispatch('refreshListData')
                        this.loading=false
                        this.info='The User has been Added'
                        this.showDismissibleAlert=true
                        this.getIssuers()
                        // this.users.push(response.data.data)
                    }else{
                        this.loading=false
                        this.info=response.data.msg
                        this.showDismissibleAlert=true
                    }
                }).catch(err=>{
                    this.loading=false
                    this.info=err
                    this.showDismissibleAlert=true

                })
    }
    },
    removeIssuerFromDepartment(user){
        let payload=Object.assign({},user)
        payload['department_id']=this.department.department_id
        axios.post(this.$store.state.api+'removeItemIssuer',payload,{headers: {
                Authorization: 'Bearer '+ this.$store.state.jwt
                }})
                .then(response=>{
                    if(response.data.status=='success'){
                        //this.$store.dispatch('refreshListData')
                        this.loading=false
                        this.info='The User has been removed'
                        this.showDismissibleAlert=true
                        // this.users=this.users.filter((t)=>{return t.userid!=user.userid})
                        this.getIssuers()
                    }else{
                        this.loading=false
                        this.info=response.data.msg
                        this.showDismissibleAlert=true
                    }
                }).catch(err=>{
                    this.loading=false
                    this.info=err
                    this.showDismissibleAlert=true

                })

        },
        addAuthoriserToDepartment(){
        this.$refs.addAuthForm.validate()
        if(this.authValid){
            this.loading=true
      this.authForm['department_id']=this.department.department_id

      axios.post(this.$store.state.api+'addIssueAuthoriserToDepartment',this.authForm,{headers: {
                Authorization: 'Bearer '+ this.$store.state.jwt
                }})
                .then(response=>{
                    if(response.data.status=='success'){
                        //this.$store.dispatch('refreshListData')
                        this.loading=false
                        this.info='The User has been Added'
                        this.showDismissibleAlert=true
                        this.getAuthorisers()
                        // this.users.push(response.data.data)
                    }else{
                        this.loading=false
                        this.info=response.data.msg
                        this.showDismissibleAlert=true
                    }
                }).catch(err=>{
                    this.loading=false
                    this.info=err
                    this.showDismissibleAlert=true

                })
    }
    },
    removeAuthoriserFromDepartment(user){

        axios.post(this.$store.state.api+'removeIssueAuthoriserFromDepartment',user,{headers: {
                Authorization: 'Bearer '+ this.$store.state.jwt
                }})
                .then(response=>{
                    if(response.data.status=='success'){
                        //this.$store.dispatch('refreshListData')
                        this.loading=false
                        this.info='The User has been deleted'
                        this.showDismissibleAlert=true
                        // this.users=this.users.filter((t)=>{return t.userid!=user.userid})
                        this.getAuthorisers()
                    }else{
                        this.loading=false
                        this.info=response.data.msg
                        this.showDismissibleAlert=true
                    }
                }).catch(err=>{
                    this.loading=false
                    this.info=err
                    this.showDismissibleAlert=true

                })

        },
        getUsers(){
        this.loading=true
        axios.post(this.$store.state.api+'getUsersByDepartment',this.department,{headers: {
                Authorization: 'Bearer '+ this.$store.state.jwt
                }})
                .then(response=>{
                    if(response.data.status=='success'){
                        this.users=response.data.data
                        this.loading=false
                    }else{
                        this.loading=false
                        this.info=response.data.msg
                        this.showDismissibleAlert=true
                    }
                }).catch(err=>{
                    this.loading=false
                    this.info=err
                    this.showDismissibleAlert=true

                })
        },
        getIssuers(){
        this.loading=true
        axios.post(this.$store.state.api+'itemIssuersByDepartment',this.department,{headers: {
                Authorization: 'Bearer '+ this.$store.state.jwt
                }})
                .then(response=>{
                    if(response.data.status=='success'){
                        this.issuers=response.data.data
                        this.loading=false
                    }else{
                        this.loading=false
                        this.info=response.data.msg
                        this.showDismissibleAlert=true
                    }
                }).catch(err=>{
                    this.loading=false
                    this.info=err
                    this.showDismissibleAlert=true

                })
        },
        getAuthorisers(){
        this.loading=true
        axios.post(this.$store.state.api+'getIssueAuthorisersByDepartment',this.department,{headers: {
                Authorization: 'Bearer '+ this.$store.state.jwt
                }})
                .then(response=>{
                    if(response.data.status=='success'){
                        this.authorisers=response.data.data
                        this.loading=false
                    }else{
                        this.loading=false
                        this.info=response.data.msg
                        this.showDismissibleAlert=true
                    }
                }).catch(err=>{
                    this.loading=false
                    this.info=err
                    this.showDismissibleAlert=true

                })
        },
    },
    watch:{
        department: function (){
            this.getUsers()
            this.getAuthorisers()
            this.getIssuers()
        }
    }
}
</script>