<template>
    <div>
        <v-btn
            :background-color="$store.getters.getColorPalette().background1ColorCode"
            :color="$store.getters.getColorPalette().accentCode"
            small
            text
            :dark="$store.getters.getColorPalette().isDark"
            outlined
            @click="dialog = !dialog"
        >
            {{ $store.getters.getTextMap().create_user_group }} <v-icon right :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode">mdi-account-group-outline</v-icon>
        </v-btn>
        <v-dialog v-model="dialog" max-width="390">  
            <CreateUserGroup v-on:close="onClose" />
        </v-dialog>
    </div>
</template> 
<script>
import CreateUserGroup from '@/components/account_components/CreateUserGroup'
export default {
    name:'CreateUserGroupModal',
    components:{
        CreateUserGroup
    },
    data () {
    return {
        dialog: false,
        valid:false,
    }
    },
    methods:{
        onClose(){
            this.dialog=false
            this.$emit('close')
        }
    }
}
</script>
<style scoped>
.emailgrp{
    border:3px solid;
    border-radius:20px;
}
.theme--light.v-card{
    background-color: #263238
}
</style>