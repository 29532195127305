<template>

        <v-container fluid>
            <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
            <v-card flat :color="$store.getters.getColorPalette().backgroundColorCode" :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}">
            <v-card-title>{{notificationGroup.name}} <v-progress-circular
      indeterminate
      v-if="loading"
      color="primary"
    ></v-progress-circular>
    <v-spacer></v-spacer>
    <v-dialog
      v-model="dialog"
      max-width="290"
      >
      <CreateEmailGroup :notificationGroup="notificationGroup" v-on:close="onClose" />
    </v-dialog>
    <span v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])">
      <v-icon
        small
        class="mr-2"
        v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['betaaccess'])"
        @click="dialog=!dialog"
      >
        mdi-pencil
      </v-icon>
        <DeleteConfirmation  v-on:confirm="deleteGroup()"  title="Delete Confirmation"  description="Are you sure you want to delete this?">  
      <v-icon
        small
      >
        mdi-delete
      </v-icon>
  </DeleteConfirmation>
    </span>
    </v-card-title>
        <v-divider></v-divider>
        <v-card-subtitle >
        
        <v-row v-if="loading">
            <v-col>
                <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="text"
                        ></v-skeleton-loader>
            </v-col>
            <v-col>
                <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="text"
                        ></v-skeleton-loader>
            </v-col>
        </v-row>
        
        <v-row v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess']) ">
            <v-col cols="1">
                <span><v-btn   fab :dark="$store.getters.getColorPalette().isDark" @click="addDialog=!addDialog" color="blue" >
        <v-icon>mdi-plus</v-icon>
      </v-btn></span>
            </v-col>
            <v-col v-if="addDialog">
                <v-form
    ref="addForm"
    v-model="valid">
                <v-row>
                    <v-col>
                        <v-text-field
        :loading="loading"
        v-model="form.name"
        :counter="25"
        :rules="nameRules"
        :label="$store.getters.getTextMap().name"
        required
    ></v-text-field>
                    </v-col>
                    <v-col v-if="notificationGroup.notification_type==='email'">
                        <v-text-field
            :loading="loading"
      v-model="form.address"
      :counter="40"
      :rules="emailRules"
      :label="$store.getters.getTextMap().email_address"
      required
    ></v-text-field>
                    </v-col>
                    <v-col v-if="notificationGroup.notification_type==='sms'">
                      <v-text-field 
                    :dark="$store.getters.getColorPalette().isDark" 
                    :loading="loading"
                    v-model="form.address"
                    :counter="10"
                    :rules="[rules.required,rules.noSpace,rules.integ,rules.nonZeroLengthDynamic(10)]"
                    :label="$store.getters.getTextMap().phone"
                    required
                ></v-text-field>
                    </v-col>
                    
                    <v-col>
                        <v-btn
          v-if="!loading"
          :color="$store.getters.getColorPalette().submitbtnColor"
            text
            @click="addTupleToGroup"
          >
        {{$store.getters.getTextMap().add}}
          </v-btn>
                    </v-col>
                </v-row>
                </v-form>
            </v-col>
            
        </v-row>
        <v-row>
            <v-col>
                <v-simple-table height="150px">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            <strong>{{$store.getters.getTextMap().name}}</strong>
          </th>
          <th class="text-left">
            <strong>{{$store.getters.getTextMap().address}}</strong>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in tuples"
          :key="item.address"
        >
          <td>{{ item.name }}</td>
          <td>{{ item.address }}</td>
          <td v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess']) "><v-icon small color="red" @click="deleteTupleFromGroup(item)">mdi-delete</v-icon></td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
            </v-col>
        </v-row>
        </v-card-subtitle>
            </v-card>
        </v-container>

</template>

<script>
import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'
import DeleteConfirmation from '@/components/DeleteConfirmation'
import FormRules from '@/utillities/FormRules'
import CreateEmailGroup from '@/components/crud_components/CreateEmailGroup'
//import AddTupleToList from '@/components/crud_components/AddTupleToList'
export default {
    name:'NotificationGroup',
    props:['notificationGroup'],
    components:{
        InfoAlert,
        //AddTupleToList
        CreateEmailGroup,
        DeleteConfirmation
    },
    mounted(){
        this.getTuples()
    },
    data(){
        return {
            showDialog:false,
            loading:false,
            info:'',
            showDismissibleAlert:false,
            addDialog:false,
            valid:false,
            dialog: false,
            tuples:[],
            form:{
              address:null,
              name:null,
            },
            stringRules: [
        v => !!v || 'Required',
        v => /\S+/.test(v) || 'Required',
        v => (v && v.length <= 30 && v.length > 0) || 'Cannot be more than 30 characters',
      ],
      rules:FormRules.rules,
      bitRules:[
          v => !!v || 'Required',
          v => /[0-9]+$/.test(v) || 'It should be a number',
          v => (v && !isNaN(v))|| 'It should be a valid number',
          v => (v && v.length <= 2 && v.length > 0) || "It can't be more that 2 digits",
      ],
      emailRules:FormRules.emailRules,
      nameRules:FormRules.nameRules
        }
    },

    computed:{
        
        
    },

    methods:{
      onClose(){
            this.dialog=false,
            this.$emit('close')
            },
        getTuples(){
            this.loading=true
            axios.post(this.$store.state.api+'getTuplesByNotificationGroup',this.notificationGroup,{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                    }})
                    .then(response=>{
                        if(response.data.status=='success'){
                            this.tuples=response.data.data
                            this.loading=false
                        }else{
                            this.loading=false
                            this.info=response.data.msg
                            this.showDismissibleAlert=true
                        }
                    }).catch(err=>{
                        this.loading=false
                        this.info=err
                        this.showDismissibleAlert=true

                    })
        },
        deleteGroup(){

            axios.post(this.$store.state.api+'deleteNotificationGroup',this.notificationGroup,{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                    }})
                    .then(response=>{
                        if(response.data.status=='success'){
                            this.$store.dispatch('refreshNotificationGroups')
                            this.loading=false
                            this.info='The Group has been deleted'
                            this.showDismissibleAlert=true
                        }else{
                            this.loading=false
                            this.info=response.data.msg
                            this.showDismissibleAlert=true
                        }
                    }).catch(err=>{
                        this.loading=false
                        this.info=err
                        this.showDismissibleAlert=true

                    })

        },
        deleteTupleFromGroup(tuple){

            axios.post(this.$store.state.api+'deleteNotificationUser',tuple,{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                    }})
                    .then(response=>{
                        if(response.data.status=='success'){
                            //this.$store.dispatch('refreshListData')
                            this.loading=false
                            this.info='The User has been deleted'
                            this.showDismissibleAlert=true
                            this.tuples=this.tuples.filter((t)=>{return t.address!=tuple.address})
                        }else{
                            this.loading=false
                            this.info=response.data.msg
                            this.showDismissibleAlert=true
                        }
                    }).catch(err=>{
                        this.loading=false
                        this.info=err
                        this.showDismissibleAlert=true

                    })

        },
        addTupleToGroup(){
            this.$refs.addForm.validate()
            if(this.valid){
                this.loading=true
          this.form['group_id']=this.notificationGroup.group_id

          axios.post(this.$store.state.api+'addTupleToNotificationGroup',this.form,{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                    }})
                    .then(response=>{
                        if(response.data.status=='success'){
                            //this.$store.dispatch('refreshListData')
                            this.loading=false
                            this.info='The Record has been added'
                            this.showDismissibleAlert=true
                            this.tuples.push(response.data.tuple)
                        }else{
                            this.loading=false
                            this.info=response.data.msg
                            this.showDismissibleAlert=true
                        }
                    }).catch(err=>{
                        this.loading=false
                        this.info=err
                        this.showDismissibleAlert=true

                    })
        }
        },
      cancel(){
          this.showDialog=false
        },
        confirm(){
          this.showDialog=false
        }    
    },
    watch:{
        notificationGroup: function (){
            this.getTuples()
        }
    }

}
</script>